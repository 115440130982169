<template>
    <div id="page-smsc-details" :class="{ 'on-sidebar': on_sidebar }">
        <app-dialog-info v-model="isAddingBlocklist">
            <template #head>
                <h2 class="heading">Add to Blocklist</h2>
            </template>
            <template #body>
                <div class="content">
                    <p>Please enter the number you wish to block.</p>
                    <br>
                </div>
                <app-input label="MSISDN" type="number" :error="blocklistAddError" v-model="blocklistAddMSISDN" />
                <div class="btns">
                    <button class="btn btn-primary btn-left" @click="addBlockMSISDN">Add</button>
                    <button class="btn btn-primary btn-cancel btn-right" @click="closeIsAddingBlocklist">Cancel</button>
                </div>
                <app-loader v-if="blocklistAddloading" fixed-on="desktop"></app-loader>
            </template>
        </app-dialog-info>
        <app-dialog-info :value="isRemovingBlocklist" @input="clearRemovingBlocklist">
            <template #head>
                <h2 class="heading">Remove from blocklist </h2>
            </template>
            <template #body>
                <div class="content">
                    <p>You have selected to remove <b>{{blocklistRemoveMSISDN}}</b> from the blocklist.</p>
                    <br>
                </div>
                <div class="btns">
                    <button class="btn btn-primary btn-left" @click="removeBlockMSISDN">Remove</button>
                    <button class="btn btn-primary btn-cancel btn-right" @click="clearRemovingBlocklist">Cancel</button>
                </div>
                <app-loader v-if="blocklistRemoveLoading" fixed-on="desktop"></app-loader>
            </template>
        </app-dialog-info>

        <app-loader v-if="loading" fixed-on="desktop"></app-loader>

        <div class="container">
            <div class="row" v-if="!on_sidebar">
                <div class="col-12">
                    <router-link class="btn btn-back-to" :to="{ name: 'smsc-entries' }">Back to Entries</router-link>

                    <div class="header-info">
                        <h1 class="heading">Details</h1>
                    </div>
                </div>
            </div>

            <div v-if="details" class="row details">
                <div class="col-mob-12"
                    :class="on_sidebar ? 'col-12' : 'col-6'"
                >
                    <app-table-details
                        :rows="rows.group"
                        :data="details"
                        title="Details"
                    />
                </div>
            </div>
            <div class="row details">
                <div v-if="details" class="col-mob-12" :class="on_sidebar ? 'col-12' : 'col-6'">
                    <div class="app-table blocked-msisdn-table">
                        <div class="app-table-box">
                            <div class="head">
                                <div class="col">
                                    <h2 class="heading">Blocked Numbers</h2>
                                </div>
                                <div class="col">
                                    <button class="btn btn-secondary btn-small msisdn-block-add-btn" @click="openIsAddingBlocklist">Add</button>
                                </div>
                            </div>
                            <div class="rows">
                                <div v-for="(MSISDN) in details.BlockedMSISDNs" :key="MSISDN" class="row">
                                    <div class="col">
                                        {{MSISDN}}
                                    </div>
                                    <div class="col">
                                        <button class="btn btn-table-action action-remove action-secondary" @click="blocklistRemoveMSISDN = MSISDN">
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appDialogInfo from '@/components/app-dialog-info'
import appLoader from '@/components/app-loader'
import appTable from '@/components/app-table'
import appInput from '@/components/app-input'
import appTableDetails from '@/components/app-table-details'
import blocklistTable from '@/views/smsc/blocklist/blocklist-table.vue'
import errMessage from '@/helpers/errMessage'

import formatDateNano from '@/helpers/format-date-nano'

export default {
    props: {
        msisdn: { type: String, default: null },
    },

    components: {
        appDialogInfo,
        appLoader,
        appTable,
        appTableDetails,
        blocklistTable,
        appInput
    },

    data() {
        return {
            blocklistAddMSISDN: '',
            blocklistAddError: '',
            blocklistRemoveMSISDN: '',
            isAddingBlocklist: false,
            details: null,
            blocklistAddloading: false,
            blocklistRemoveLoading: false,

            rows: {
                group: [
                    { key: 'MSISDN', title: 'MSISDN' },
                    { key: 'ChangeTimeNanos', title: 'Last Updated', type: 'datenanos' },
                    { key: 'Status', title: 'Ported In', type: 'yesno' },
                    { key: 'BillingTypeID', title: 'Billing Type ID'},
                ],
            },

            loading: false,

            on_sidebar: false,
        }
    },

    created() {
        this.init()
    },

    mounted() {
        this.on_sidebar = this.$parent.$el.classList.contains('app-sidebar-details')
    },

    methods: {
        init() {
            this.loadDetails()
        },
        
        loadDetails () {
            if (this.current_msisdn) {
                this.loading = true
                this.$store.dispatch('api_smsc/GetSMSCEntryByMSISDN', { MSISDN: this.current_msisdn, SPID: this.current_spid }).then(resp => {
                    this.details = resp
                    this.loading = false
                }).catch(error => {
                    console.error(error)
                    if (this.on_sidebar) {
                        this.$emit('close-sidebar')
                    } else {
                        this.$router.push({ name: 'smsc-entries', replace: true })
                    }
                })
            }
        },

        addBlockMSISDN () {
            let newMSISDNs = []
            newMSISDNs.push('' + this.blocklistAddMSISDN)
            this.blocklistAddloading = true
            this.$store.dispatch('api_smsc/AddToBlocklist', { MSISDN: this.current_msisdn, SPID: this.current_spid, AffectedMSISDNs: newMSISDNs }).then(resp => {
                this.details.BlockedMSISDNs = resp.BlockedMSISDNs
                this.details.IsBlocklistEnabled = resp.IsBlocklistEnabled
                this.closeIsAddingBlocklist()
                this.blocklistAddloading = false
            }).catch((error) => {
                this.blocklistAddloading = false
                this.blocklistAddError = errMessage(error)
            })
        },

        removeBlockMSISDN () {
            let newMSISDNs = []
            newMSISDNs.push('' + this.blocklistRemoveMSISDN)
            this.blocklistRemoveLoading = true
            this.$store.dispatch('api_smsc/RemoveFromBlocklist', { MSISDN: this.current_msisdn, SPID: this.current_spid, AffectedMSISDNs: newMSISDNs }).then(resp => {
                this.details.BlockedMSISDNs = resp.BlockedMSISDNs
                this.details.IsBlocklistEnabled = resp.IsBlocklistEnabled
                this.clearRemovingBlocklist()
                this.blocklistRemoveLoading = false
            }).catch(error => {
                this.clearRemovingBlocklist()
                console.error(error)
                this.blocklistRemoveLoading = false
            })
        },

        openIsAddingBlocklist () {
            this.isAddingBlocklist = true
            this.blocklistAddMSISDN = ''
        },

        closeIsAddingBlocklist () {
            this.isAddingBlocklist = false
            this.blocklistAddMSISDN = ''
        },

        clearRemovingBlocklist () {
            this.blocklistRemoveMSISDN = ''
        }
    },

    watch: {
        current_msisdn(val) {
            if (val) {
                this.loadDetails()
            }
        },
    },

    computed: {
        ...mapGetters([
            'current_spid'
        ]),
        isRemovingBlocklist () {
            if (this.blocklistRemoveMSISDN) {
                return true
            }
            return false
        },
        current_msisdn() {
            if (this.$route.name === 'smsc-entries-details') {
                return this.$route.params.msisdn
            }
            return this.msisdn
        },
    },
}
</script>

<style lang="scss">
#page-smsc-details {
    margin: 24px 0 80px;

    .btn-back-to {
        margin-bottom: 12px;
    }

    .header-info {
        display: flex;
        justify-content: space-between;
        width: fit-content;
        min-height: 62px;
        margin-bottom: 40px;
    }

    .details {
        margin-bottom: 30px;

        .col-6,
        .col-12 {
            margin-bottom: 30px;
        }

        &:last-child { margin: 0 };
    }

    .blocked-msisdn-table {
        .msisdn-block-add-btn {
            padding-left: 12px;
            padding-right: 12px;
            float: right;
        }
        
        .app-table-box {
            border-radius: 4px;
            .head {
                background-color: var(--color-table-row-bg);
                .col {
                    text-transform: none;
                }
            }
            .row {
                .col:first-child{
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width: $tablet-size) {
    #page-smsc-details {
        .header-info {
            width: 100%;
            margin-bottom: 48px;
            min-height: 56px;
        }
    }
}

@media (max-width: $mobile-size) {
    #page-smsc-details {
        margin: 24px 0 64px;

        .btn-back-to {
            font-size: 16px;
            line-height: 18px;
            margin-bottom: 8px;
        }

        .header-info {
            flex-direction: column;
            margin-bottom: 32px;
            min-height: 40px;
        }

        .details {
            margin-bottom: 15px;

            .col-6,
            .col-12,
            .col-mob-12 {
                margin-bottom: 15px;
            }
        }
    }
}
</style>
